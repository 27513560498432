.sc-module {
    flex: 0 0 auto;

    .content-block {
        position: relative;
        z-index: 1;
    }

    &-infinity {
        @include media-breakpoint-up(md) {
            flex-wrap: wrap;
        }
    }

    @include media-breakpoint-down(sm) {
        &-showmore {
            button {
                width: 100%;
            }
        }
    }

    @include media-breakpoint-down(md) {
        background: #FFFFFF;
        max-width: 100vw;
        --bs-gutter-x: 1.5rem;
    }

    &-item {
        @include media-breakpoint-up(sm) {
            width: 50%;
        }

        @include media-breakpoint-up(md) {
            width: 33.33333333%;
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                .sc-module-btns {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            width: 20%;
        }

        &-attr {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -100%;
            transform: translateY(100%);
            background: #F2F4F8;
            border: 1px solid #F2F2F5;
            border-radius: 0px 0px 4px 4px;
            opacity: 0;
            visibility: hidden;
            transition: opacity $mainTransition;

            &-item:not(:first-child) {
                margin-top: 0.25rem;
            }
        }

        &:hover {
            z-index: 3;

            .sc-module-item-attr {
                opacity: 1;
                visibility: visible;
                bottom: 0;
                box-shadow: 0px 2px 0 rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);
            }
        }
    }

    &-img {
        img {
            display: block;
            margin: 0 auto;
        }
    }

    &-btns {
        top: 0;
        right: 0;
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-out;
        gap: 0.5rem;

        button {
            filter: none;
            
            .sc-btn-icon {
                width: 18px;
                height: 16px;
                mask-repeat: no-repeat;
            }
        }
    }

    &-stickers {
        flex-wrap: wrap;
        gap: 0.25rem;
    }

    &-sticker {
        padding: 0.125rem 0.25rem;
        color: #FFFFFF;
        white-space: nowrap;
        text-transform: lowercase;

        &-red {
            background: $redColor;
        }

        &-green {
            background: $greenColor;
        }

        &-orange {
            background: #E67A00;
        }

        &-blue {
            background: #004FC4;
        }

        &.out-of-stock {
            color: #fff;
            background: #8f90a6;
        }
    }

    &-header {
        letter-spacing: 0.01em;
    }

    &-title {
        font-size: 0.75rem;
        line-height: 1rem;

        @include media-breakpoint-up(md) {
            font-size: 0.875rem;
            line-height: 1.5rem;
        }

        &:hover {
            color: $linksColor;
            text-decoration: underline;
        }
    }

    &-rating {
        padding: 0.25rem;
        background: #F8F9FA;
        max-width: fit-content;

        &-star {
            background-image: url("../img/module-star-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 14px;
            height: 14px;
            display: inline-block;

            & + & {
                margin-left: 2px;
            }

            &-is {
                background-image: url("../img/module-star-icon-is.svg");
            }
        }
    }

    &-reviews {
        margin-left: 0.25rem;

        span {
            line-height: 1;
        }
    }

    &-price {
        font-size: 0.875rem;

        @include media-breakpoint-up(sm) {
            font-size: 1.25rem;
        }

        &-old {
            text-decoration: line-through;
        }

        @media (min-width: $break-lg) and (max-width: $break-xl-max) {
            &-box {
                display: flex;
                flex-direction: column;
            }
        }
    }

    &-cart {
        .sc-btn-primary {
            @include media-breakpoint-up(sm) {
                flex: 0 0 39px;
                max-width: 39px;

                .sc-btn-text {
                    display: none;
                }
            }
        }
    }

    &-quantity {
        border: 1px solid #F2F2F5;

        .form-control {
            flex: 0 0 46px;
            max-width: 46px;
            border-top: 0;
            border-bottom: 0;
            border-radius: 0;
            padding: 0.25rem 0;
            text-align: center;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        button {
            border: 0;
            padding: 0.25rem 0.625rem;
            background: transparent;
            flex-grow: 1;

            span {
                width: 20px;
                height: 20px;
            }

            .plus {
                background: url(../img/sprite.svg#include--quantity-plus-icon) no-repeat center center;
            }

            .minus {
                background: url(../img/sprite.svg#include--quantity-minus-icon) no-repeat center center;
            }
        }
    }

    &-showmore {
        position: relative;
        z-index: 2;

        .oct-animated {
            .sc-btn-icon {
                animation: loop 2s infinite;
            }
        }
    }
}

@keyframes loop {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(720deg);
    }
}

@include media-breakpoint-down(sm) {
    [data-type="width100"] {
        --bs-gutter-y: 1rem !important;

        .sc-module-info {
            padding-top: 0.5rem;
        }

        .sc-module-title {
            font-size: 0.875rem;
        }

        .sc-module-cart {
            .sc-btn-primary {
                order: 1;

                @media (min-width: 390px) {
                    padding-right: 2rem;
                    padding-left: 2rem;
                }

                @media (max-width: 359.98px) {
                    .sc-btn-text {
                        display: none;
                    }
                }
            }

            .sc-module-quantity {
                order: 0;
            }
        }
    }

    [data-type="width50"] {
        padding: 0 0.25rem;

        .sc-module-item,
        .product-layout {
            @media (min-width: $break-xxs) {
                width: 50%;
                padding: 0 0.25rem;
            }
        }

        .sc-module-info {
            margin-top: 1rem;
            line-height: 1.25rem;
        }

        .sc-module-title {
            word-break: break-all;
        }

        .sc-module-cart {
            flex-direction: column;

            .sc-btn-primary {
                max-width: 100%;
                margin-top: 1rem;
                padding-left: 0;
                padding-right: 0;
                max-height: 39px;
            }
        }

        .sc-module-price-box {
            margin-top: 0 !important;
            display: flex;
            flex-direction: column;
        }
    }

    [data-type="split"] {
        --bs-gutter-y: 1rem !important;
        --bs-gutter-x: 1.5rem !important;

        .sc-module-item {
            padding-left: 0;
            padding-right: 0;
        }

        .sc-filter-btn .sc-btn {
            margin-bottom: 0 !important;
        }
        
        .content-block {
            flex-direction: row !important;
            flex-wrap: wrap;
            height: auto !important;
        }

        .sc-module-img,
        .sc-module-caption {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .sc-module-img {
            padding-right: 0.5rem;
        }

        .sc-module-caption {
            padding-left: 0.5rem;
        }

        .sc-module-info {
            line-height: 1.25rem;
        }

        .sc-module-title {
            font-size: 0.875rem;
        }

        .sc-module-cart {
            flex: 0 0 100%;
            max-width: 100%;
            padding-top: 1rem;

            .sc-btn-primary {
                order: 1;
                flex: 0 0 calc(50% - 1rem);

                @media (min-width: 410px) {
                    padding-right: 2rem;
                    padding-left: 2rem;
                }
            }

            .sc-module-quantity {
                order: 0;
                flex: 0 0 calc(50% - 1rem);
                margin-right: 0.5rem;
            }
        }

        .sc-module-price-box {
            display: flex;
            flex-direction: column;
        }

        .sc-module-price {
            font-size: 1.125rem;
        }
    }

    [data-type="minimal"] {
        .sc-module-item {
            padding: 0;
        }
        .content-block {
            flex-direction: row !important;
            flex-wrap: wrap;
            height: auto !important;
            position: relative;
        }

        .sc-module-img {
            flex: 0 0 100px;
            max-width: 100px;
            align-self: center;
        }

        .sc-module-caption {
            flex: 0 0 calc(100% - 100px);
            max-width: calc(100% - 100px);
            padding-left: 1rem;
        }

        .sc-module-model,
        .sc-module-rating,
        .sc-module-quantity,
        .sc-module-stickers {
            display: none !important;
        }

        .sc-module-price-box {
            padding-bottom: 0 !important;
            padding-right: 3rem;
            display: flex;
            flex-direction: column;

            .price-tax {
                margin-bottom: 0.25rem;
            }
        }

        .sc-module-cart {
            .sc-btn-primary {
                position: absolute;
                right: 1rem;
                bottom: 1rem;
            }

            .sc-btn-text {
                display: none;
            }
        }
    }

    [data-type="slider"] {
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        --bs-gutter-x: 1.5rem !important;

        .sc-module-infinity > .row {
            display: flex;
            flex-wrap: nowrap;
            overflow-y: auto;
            padding: 0 1rem 1.25rem 1rem !important;
        }

        .sc-module-item {
            flex: 0 0 65%;

            & + .sc-module-item {
                margin-left: 1rem;
            }
        }

        .sc-module-info {
            margin-top: 1rem;
            line-height: 1;
        }

        .sc-module-price-box {
            display: flex;
            flex-direction: column;
        }

        .sc-module-model,
        .sc-module-rating,
        .price-tax,
        .sc-module-sticker:not(.sc-module-sticker-infinity),
        .sc-module-cart-btn .sc-btn-text {
            display: none !important;
        }

        .sc-btn-with-icon {
            width: 40px;
            height: 40px;
        }
    }
}